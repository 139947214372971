



























































import {
  defineComponent, ref, computed, PropType,
} from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';

interface IItem {
  title: string;
  text: string;
}

interface ISlide {
  image: {
    filename: string;
    alt: string;
  };
  values: {
    title: string;
    subTitle: string;
    items: IItem[];
  };
  tabletUpImage: {
    filename: string;
    alt: string;
  };
}

export default defineComponent({
  name: 'PackaegIncludedItemsSlide',
  components: { FCImage },
  props: {
    slide: {
      type: Object as PropType<ISlide>,
      required: true,
    },
  },
  setup(props, context) {
    const isActive = ref(false);

    const isOnActive = computed(() => {
      if (isActive.value) {
        return {
          backgroundImage: `url(${props.slide.tabletUpImage.filename})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '612px',
          position: 'relative',
        };
      }

      return {};
    });

    const getCopy = computed(() => (isActive.value ? 'Back' : 'More Info'));
    function toggleActive() {
      isActive.value = !isActive.value;
      // emit event for carousel arrow color change
      context.emit('carouselArrowColor', isActive.value ? 'dark-gray' : 'blue');
    }

    return {
      isActive,
      isOnActive,
      getCopy,
      toggleActive,
      getTextColor: computed(() => (isActive.value ? 'fc-text-white' : 'fc-text-blue')),
    };
  },
});
